import { Component, OnInit } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup, Validator, Validators } from '@angular/forms'
import { GeneralserviceService } from '../../generalservice.service';
import { ActivatedRoute, Router } from '@angular/router';
    

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrl: './forgotpassword.component.scss'
})
export class ForgotpasswordComponent {
    UserForm: FormGroup;
    RoleID: any;
    submitAttempt: boolean | undefined;
    Mobile: any;
    Token: any;
    Status: boolean = true;
    Password: any;
    ConfirmPassword: any;
    SelectedPassword: any;
    passwordType: string = 'password';
    passwordIcon: string = 'eye-off-outline';
    LoginDetails1: any;
    LoginDetails: any;
    showPassword: boolean = false;
    data: any;
    showPasswords: boolean = false;
    mobile: any;
    //ForgotPassword: any; ForgotPassword1: any;
    constructor(private fb: FormBuilder,
        public ActiveRoute: ActivatedRoute, public router: Router, private general: GeneralserviceService) {
        debugger
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        if (this.LoginDetails != null) {
            this.mobile = this.LoginDetails[0].Mobile

        } else {
            //this.mobile1 = this.activate.snapshot.paramMap.get("Mobile")
            this.mobile = this.ActiveRoute.snapshot.paramMap.get("Mobile")
        }

        debugger
        this.RoleID = this.ActiveRoute.snapshot.paramMap.get("RoleID")
        //this.Mobile = localStorage.getItem("Mobile")
        this.UserForm = this.fb.group({
            // ConfirmPassword: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(6), Validators.required])],
            Password: ['', Validators.compose([Validators.maxLength(30), Validators.minLength(6), Validators.required])],


        },

            {
                // validator: this.matchingPasswords('Password', 'Confirmpassword')

            })
        //this.UserForm.patchValue({ Disclaimer: null });
    }

    ngOnInit() {
        //this.GetForgotPassword();
    }
    onPassword(Password: any) {
        this.SelectedPassword = Password.value;
    }

    togglePassword() {
        debugger
        this.showPassword = !this.showPassword;
    }
    togglePasswords() {
        debugger
        this.showPasswords = !this.showPasswords;
    }



    // Method to toggle password visibility

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    //GetForgotPassword() {
    //  var UploadFile = new FormData();
    //  UploadFile.append("Param1", this.LoginDetails[0].RegID);
    //  UploadFile.append("Param2", '4');
    //  var url = "";
    //  this.general.PostData(url, UploadFile).subscribe((result: any) => {
    //    this.ForgotPassword = result;
    //    this.ForgotPassword1 = result;
    //  }, err => {
    //    this.general.presentToast("Something went wrong. Please try again later");
    //  })

    //}



    async Update() {
        debugger
        if (this.Password != null || this.Password != undefined) {
            //if (this.Password == this.ConfirmPassword) {
            var obj = [{
                Mobile: this.Mobile,
                Password: this.Password
            }]
            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(obj));
            UploadFile.append("Flag", '10');
            var url = "api/FinancierLaw/FA_Registration_CRUD";
            this.general.PostData(url, UploadFile).subscribe((data: any) => {
                if (data == "SUCCESS") {
                    var UploadFile = new FormData();
                    UploadFile.append("Param1", this.Mobile);
                    UploadFile.append("Param2", this.Password);
                    var url = "api/FinancierLaw/Roles_Login";
                    this.general.PostData(url, UploadFile).subscribe((result: any) => {
                        debugger
                        if (result != "Invalid User") {
                            this.succussAlert(result)
                                //.("SUCCESS", "Update password successfully.");
                            //this.navCtrl.navigateForward("/login");
                            //localStorage.setItem("LoginDetails", JSON.stringify(result));
                            if (!this.LoginDetails) {
                                this.router.navigate(['/Login']);
                            }
                            //else if (result[0].RoleID == 2) {
                            //    this.router.navigate(['/1home']);
                            //} else if (result[0].RoleID == 3) {
                            //    this.router.navigate(['/1home']);
                            //} else if (result[0].RoleID == 9) {
                            //    this.router.navigate(['/1home']);
                            //}
                        } else {

                        }
                    })
                }
            })
            //} else {
            //  this.general.presentAlert("Alert", "Please enter new password as confirm password.");
            //}
        } else {
            this.general.presentAlert("Alert", "Please enter password.");
        }

    }

    succussAlert(val: any) {
        const enteredOtp = window.alert("Your password update Successfuly");

    }


    showOtpInputAlert(expectedOtp: any) {
        debugger
        // Prompt user to enter OTP using window.prompt()
        const enteredOtp = window.prompt("Enter the OTP sent to your email:");

        if (enteredOtp === null) {
            // User canceled the prompt
            console.log('OTP input canceled');
            return;
        }

        // Validate the entered OTP
        if (enteredOtp === expectedOtp.toString()) {
            // OTP is correct, navigate to the change password page
            this.router.navigate(['/Forgotpassword']);
            console.log('OTP is correct. Navigating to change password page.');
        } else {
            // Display an error message using window.alert
            window.alert('Invalid OTP. Please try again.');

            // Retry OTP input
            this.showOtpInputAlert(expectedOtp);
        }
    }

    //doRefresh(event: any) {
    //    this.data = null; // this is replacement of splice
    //    this.ngOnInit();
    //    this.Update();
    //    setTimeout(() => {
    //        this.router.navigate(['/invoice-sampah']);
    //        event.target.complete();
    //    }, 1000);

    //}

    async Update2() {
        debugger;
        if (this.Password != null || this.Password != undefined) {
            var obj = [{
                Mobile: this.Mobile,
                Password: this.Password
            }];
            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(obj));
            UploadFile.append("Flag", '10');
            var url = "api/FinancierLaw/FA_Registration_CRUD";

            this.general.PostData(url, UploadFile).subscribe((data: any) => {
                if (data == "SUCCESS") {
                    var LoginFile = new FormData();
                    LoginFile.append("Param1", this.Mobile);
                    LoginFile.append("Param2", this.Password);
                    var loginUrl = "api/FinancierLaw/Roles_Login";

                    this.general.PostData(loginUrl, LoginFile).subscribe((result: any) => {
                        debugger;
                        if (result != "Invalid User") {
                            //this.general.presentAlert("SUCCESS", "Password updated successfully.");
                            // Navigate to login page
                            this.router.navigate(['/Login']);
                        } else {
                            this.general.presentAlert("ERROR", "Invalid user credentials.");
                        }
                    }, err => {
                        this.general.presentAlert("ERROR", "Something went wrong while logging in.");
                    });
                } else {
                    this.general.presentAlert("ERROR", "Password update failed.");
                }
            }, err => {
                this.general.presentAlert("ERROR", "Something went wrong. Please try again later.");
            });
        } else {
            this.general.presentAlert("Alert", "Please enter a password.");
        }
    }

}
