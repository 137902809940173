import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-recoverymanagerform',
  templateUrl: './recoverymanagerform.component.html',
  styleUrl: './recoverymanagerform.component.scss'
})
export class RecoverymanagerformComponent {
    HomeUrl: string;
    arr: any[] = [];
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;
    LoginDetails: any;
    RegisterForm: FormGroup;
    OTPtoMobile: string;
    checkNo: number;
    Mobilenum: string;
    mobiledata: any;
    classdata: any;
    classname: any;
    deptList: any;
    deptList1: any;
    // State: any;
    StateId: any;
    DistrictName: any;
    flag: any;
    rolename: any
    Districtdata: any;
    selectedDistrictName: any;
    Statedata: any;
    State: any = [];
    selectedStateName: any;
    selectedstateid: any;
    district: any = [];
    Citydata: any = [];
    Fdata: any;
    aggrementno: any
    isUpdateMode: boolean = false;
    LegalNoticeID: number;
    cardNumber: any;
    Sname: any; NoticeFormID: any;
    VehicleType: any; VehicleType1: any;
    constructor(
        public router: Router,
        private formBuilder: FormBuilder,
        public generalService: GeneralserviceService,
        public http: HttpClient,
        public activeroute: ActivatedRoute
    ) {
        this.NoticeFormID = localStorage.getItem("noticeid");
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        // this.rolename = localStorage.getItem("clickedName");
        // this.Sname = +this.route.snapshot.paramMap.get('cardNumber');
        this.cardNumber = this.activeroute.snapshot.paramMap.get("id");
        if (this.cardNumber) {
            debugger
            localStorage.removeItem('noticeid');
        }

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        // this.LegalNoticeID = this.cardNumber;
        this.initializeForm();

        if (!this.loginDet || !this.loginDet[0] || !this.loginDet[0].RegID) {
            this.router.navigate(['/Login']);
            return;
        }
    }


    initializeForm(): void {


        this.RegisterForm = this.formBuilder.group({
            BarrowerName: ['', Validators.required],
            FatherName: ['', Validators.required],
            BarrowerAddress: ['', Validators.required],
            BarrowerMobile: ['', Validators.required],
            RecovererName: ['', Validators.required],
            RecovererSonOf: ['', Validators.required],
           RecovererOcc: ['', Validators.required],
           //VehicleType1: ['', Validators.required],
            VehicleType: ['', Validators.required],
            RecovererAge: ['', Validators.required],
            VehicleRegNo: ['', Validators.required],
            BarrowerAge: ['', Validators.required],
            BarrowerOccupation: ['', Validators.required],
            WorkingDays: ['', Validators.required],
            ConNoticeRef: ['', Validators.required],
            ReferDate: ['', Validators.required],
        });

    }


    ngOnInit() {


        this.Getforms();
        this.loadEditData();
    }

    loadEditData(): void {
        const wheeltype = localStorage.getItem('wheeltype');
        if (wheeltype) {
            this.RegisterForm.patchValue({ wheeltype });
        }
    }
    Getforms() {
        debugger;
        
        const uploadfile = new FormData();
        uploadfile.append('Param', this.NoticeFormID);
        var url = "api/FinancierLaw/FA_NoticeForms_basedon_ARGNO";
        this.generalService.Postdata1(url, uploadfile).subscribe(
            data => {
                debugger;
                this.Fdata = data;
                // const auctionedDate = this.formatDate(this.Fdata[0].datedfrom);
                this.RegisterForm.patchValue({

                    BarrowerAge: this.Fdata[0].BarrowerAge,
                    WorkingDays: this.Fdata[0].SellWithInDays,
                    BarrowerOccupation: this.Fdata[0].BarrowerOccupation,
                    BarrowerName: this.Fdata[0].BorrowerName,
                    FatherName: this.Fdata[0].FatherName,
                    BarrowerAddress: this.Fdata[0].BarrowerAddress,
                    BarrowerMobile: this.Fdata[0].BarrowerMobile,
                    RecovererName: this.Fdata[0].RecovererName,
                    RecovererSonOf: this.Fdata[0].RecovererSonOf,
                    VehicleType: this.Fdata[0].VehicleType,
                    RecovererAge: this.Fdata[0].RecovererAge,
                    VehicleRegNo: this.Fdata[0].VehicleRegNo,
                    RecovererOcc: this.Fdata[0].RecoveryOccupation,
                    ConNoticeRef: this.Fdata[0].ConNoticeRef,
                    ReferDate: this.Fdata[0].ReferDate,

                    // Add other fields as needed based on Fdata
                });
            },
            err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        );
    }
    formatDate(dateString: string): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }


    OnSubmit() {
        throw new Error('Method not implemented.');
    }

    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }




    getRegistration(value: any) {
        debugger;
        if (this.VehicleType1) {
            this.VehicleType = this.VehicleType1
           // this.VehicleType = this.RegisterForm.value.RecovererOcc
            this.RegisterForm.controls['VehicleType'].setValue(this.VehicleType);

        }
        if (!this.RegisterForm.valid) {
            alert("Please fill all the mandatory fields: VehicleRegNo,SaleDealerCompanyName, SaleDealerCompanyAddress,SellWithInDays,LoanDate.");
            return; // Exit the function if validation fails
        }
        if (this.RegisterForm.valid) {
            

            this.arr = [];
            const wheeltype = this.RegisterForm.get('wheeltype')?.value;
            var arr = [{
                // Name: value.Name,BorrowerName

                BarrowerAge: value.BarrowerAge,
                SellWithInDays: value.WorkingDays,
                BarrowerOccupation: value.BarrowerOccupation,
                BorrowerName: value.BarrowerName,
                BarrowerAddress: value.BarrowerAddress,
                FatherName: value.FatherName,
                BarrowerMobile: value.BarrowerMobile,
                RecovererName: value.RecovererName,
                RecovererSonOf: value.RecovererSonOf,
                RecoveryOccupation: value.RecovererOcc,
                VehicleType: value.VehicleType,
                RecovererAge: value.RecovererAge,
                VehicleRegNo: value.VehicleRegNo,
                ConNoticeRef: value.ConNoticeRef,
                ReferDate: value.ReferDate,
                RegID: this.loginDet[0].RegID,
                LoanAmount: 0.00,
                DueAmount: 0.00,
                AuctionReceivedMoney: 0.00,
                TotalAmount: 0.00,
                MonthlyIncome: 0.00
            }];

            const uploadfile = new FormData();
            uploadfile.append('Param', JSON.stringify(arr));
            uploadfile.append('Flag', '1');
            localStorage.setItem('wheeltype', wheeltype);
            const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
            this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
                debugger

                if (data && data) {

                    alert("Your Form submitted successfully");
                    localStorage.setItem('noticeid', data);
                    this.router.navigate(['/Recoverymanager']);


                }
            },

            );
        }
    }
    updateRegistration(value: any) {
        debugger;
        if (this.VehicleType1) {
            this.VehicleType = this.VehicleType1
            // this.VehicleType = this.RegisterForm.value.RecovererOcc
            this.RegisterForm.controls['VehicleType'].setValue(this.VehicleType);

        }
                this.arr = [];
        const wheeltype = this.RegisterForm.get('wheeltype')?.value;
        var arr = [{
            NoticeID: 6,
            NoticeFormID: this.Fdata[0].NoticeFormID,
            BarrowerAge: value.BarrowerAge,
            SellWithInDays: value.WorkingDays,
            BarrowerOccupation: value.BarrowerOccupation,
           // BarrowerName: value.BarrowerName,
            BorrowerName: value.BarrowerName,

            BarrowerAddress: value.BarrowerAddress,
            BarrowerMobile: value.BarrowerMobile,
            RecovererName: value.RecovererName,
            RecoveryOccupation: value.RecovererOcc,

            RecovererSonOf: value.RecovererSonOf,
            VehicleType: value.VehicleType,
            FatherName: value.FatherName,
            RecovererAge: value.RecovererAge,
            VehicleRegNo: value.VehicleRegNo,
            ConNoticeRef: value.ConNoticeRef,
            ReferDate: value.ReferDate,
            RegID: this.loginDet[0].RegID,
            LoanAmount: 0.00,
            DueAmount: 0.00,
            AuctionReceivedMoney: 0.00,
            TotalAmount: 0.00,
            MonthlyIncome: 0.00
        }];

        const uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(arr));
        uploadfile.append('Flag', '2');
        localStorage.setItem('wheeltype', wheeltype);
        const url = 'api/FinancierLaw/FA_NoticeFormsCRUD';
        this.generalService.Postdata1(url, uploadfile).subscribe((data: any) => {
            debugger

            if (data && data) {

                alert("Your Form Updated successfully");
                localStorage.setItem('noticeid', data);
                this.router.navigate(['/Recoverymanager']);
                //localStorage.removeItem('noticeid');

            }
        },

        );
    }
    convertToUppercase() {
        const control = this.RegisterForm.get('VehicleRegNo');
        if (control) {
            control.setValue(control.value.toUpperCase(), { emitEvent: false });
        }
    }
}
