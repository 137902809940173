<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Pawn Document Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Pawn Document</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>
                    <legend>Borrower Details</legend>
                    <form [formGroup]="RegisterForm">
                        <div class="row griddd">

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Barrower Name <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BorrowerName" placeholder="Enter Borrower Name" id="BorrowerName" required>
                                </div>
                                <br />
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="FatherName">Father Name <span style="color:red">*</span></label>
                                    <input type="text" formControlName="FatherName" placeholder="Enter Father Name" id="BarrowerAddress" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress">Barrower Address <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerAddress" placeholder="Enter Barrower Address" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Barrower Mobile <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerMobile" placeholder="Enter Barrower Mobile" onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" minlength="10" id="LoanAmount" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Barrower Age <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerAge" placeholder="Enter Barrower Age" onkeypress="return /[0-9]/i.test(event.key)" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Barrower Occupation<span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerOccupation" placeholder="Enter Barrower Occupation" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="MonthlyIncome">Monthly Income<span style="color:red">*</span></label>
                                    <input type="text" formControlName="MonthlyIncome" placeholder="Enter Monthly Income" onkeypress="return /[0-9]/i.test(event.key)" id="LoanAmount" required>
                                    <br />
                                </div>
                            </div>

                        </div>

                        <div (click)="Insert(RegisterForm.value)"></div>
                    </form>

                </fieldset>

                <form [formGroup]="RegisterForm">
                    <div formArrayName="ornaments">
                        <fieldset *ngFor="let ornament of ornaments.controls; let i = index" [formGroupName]="i" style="margin-top: 20px;">
                            <legend>Borrower Ornaments {{ i + 1 }}</legend>
                            <div class="row griddd">

                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label for="DescriptionOfArticlesPledged">Description Articles Pledged <span style="color:red">*</span></label>
                                        <input type="text" formControlName="DescriptionOfArticlesPledged" placeholder="Enter Description Of Articles Pledged" id="LoanAmount" required>
                                        <br />
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label for="GrossWeight">Gross Weight<span style="color:red"></span></label>
                                        <input type="number" formControlName="GrossWeight" placeholder="Gross Weight" required>
                                        <br />
                                    </div>
                                </div>
                                <div class="col-lg-1">
                                    <div class="form-group">
                                        <label for="NetWeight">Net Wt<span style="color:red"></span></label>
                                        <input type="number" formControlName="NetWeight" placeholder="Net Weight" required>
                                        <br />
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label for="EstimeValue">Estimated Value<span style="color:red"></span></label>
                                        <input type="number" formControlName="EstimeValue" placeholder="Estimated Value" required>
                                        <br />
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label for="LoanAmount">Loan Amount<span style="color:red"></span></label>
                                        <input type="number" formControlName="LoanAmount" placeholder="Loan Amount" required>
                                        <br />
                                    </div>
                                </div>
                                <div class="col-lg-1">
                                    <div class="row">
                                        <div class="col-lg-5" style="margin-top: 20px;">
                                            <i class="fa-solid fa-trash" (click)="removeFieldset(i)"></i>
                                        </div>
                                        <div class="col-lg-7" style="margin-top: 15px;">
                                            <i class="fa-solid fa-plus" (click)="addFieldset()"></i>
                                        </div>
                                        <div class="col-lg-7" style="margin-top: 15px;">
                                            <img src="../../../assets/img/calculate.png" style="width: 20px;cursor:pointer"
                                                 (click)="sum()"
                                                 title="Calculate"
                                                 alt="Calculate Icon" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </form>

                <fieldset>
                    <legend>Total Amounts</legend>
                    <form [formGroup]="RegisterForm">
                        <div class="row griddd">
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="TotalGW">Total Gs Wt</label>
                                    <input type="text" formControlName="TotalGW" placeholder="Enter Total Gross Wt" readonly />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="TotalNW">Total Net Wt</label>
                                    <input type="text" formControlName="TotalNW" placeholder="Enter Total Net Wt" readonly />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="TotalEstValue">Total Est.Value</label>
                                    <input type="text" formControlName="TotalEstValue" placeholder="Enter Total EstValue" readonly />
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="TotalAmount">Total Loan Amount</label>
                                    <input type="text" formControlName="TotalAmount" placeholder="Enter Total Amt" readonly required />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Rupeesinwords">Total Loan Amount in Words</label>
                                    <input type="text"
                                           formControlName="Rupeesinwords"
                                           placeholder="Enter Total Loan Amount in words"
                                           readonly
                                           required />
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>

                <fieldset style="margin-top: 20px;">
                    <legend>Photo Details</legend>
                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                            <div class="col-lg-4">
                                <p><b>Pawnbroker Photo<span style="color:red">*</span></b></p>
                                <img [src]="PawnbrokerPhoto" />
                                <div class="col-lg-8 mt-2">
                                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="Pawnbroker($event)">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <p><b>Barrower Photo<span style="color:red">*</span></b></p>
                                <img [src]="BarrowerPhoto" />
                                <div class="col-lg-8 mt-2">
                                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="Borrower($event)">
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <p><b>Witness Photo1<span style="color:red"></span></b></p>
                                <img [src]="WittnessPhoto1" />
                                <div class="col-lg-8 mt-2">
                                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="witness1($event)">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <p><b>Witness Photo2</b></p>
                                <img [src]="WittnessPhoto2" />
                                <div class="col-lg-8 mt-2">
                                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="witness2($event)">
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <p><b>Jewellery Photo1<span style="color:red"></span></b></p>
                                <img [src]="JewelleryPhoto1" />
                                <div class="col-lg-8 mt-2">
                                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="jewell1($event)">
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <p><b>Jewellery Photo2</b></p>
                                <img [src]="JewelleryPhoto2" />
                                <div class="col-lg-8 mt-2">
                                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="jewell2($event)">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <p><b>Jewellery Photo3</b></p>
                                <img [src]="JewelleryPhoto3" />
                                <div class="col-lg-8 mt-2">
                                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="jewell3($event)">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <p><b>Jewellery Photo4</b></p>
                                <img [src]="JewelleryPhoto4" />
                                <div class="col-lg-8 mt-2">
                                    <input type="file" accept=".jpg,.jpeg,.png,.jfif,.avi,.mov" (change)="jewell4($event)">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-8"></div>
                                <div class="col-lg-2">
                                    <button *ngIf="!this.RefNo" type="button" class="btn btn-primary" (click)="Insert(RegisterForm.value)">Submit</button>
                                </div>
                                <div class="col-lg-2">
                                    <button *ngIf="this.RefNo" type="button" class="btn btn-primary" (click)="update(RegisterForm.value)">update</button>
                                </div>

                            </div>


                        </div>
                    </form>
                </fieldset>

            </div>
        </div>
    </div>
</div>
