import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service'
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pawndocument',
  templateUrl: './pawndocument.component.html',
  styleUrl: './pawndocument.component.scss'
})
export class PawndocumentComponent {
    HomeUrl: string;
    arr: any[] = [];
    arr1: any[] = [];
    Fbdata: any;
    loginDet: any;
    otpSent: boolean = false;
    showLogin: boolean = false;
    showLogin1: boolean = true;
    registerAsDonor: boolean = false;
    RegId: any;LoginDetails: any;
    RegisterForm: FormGroup;
   // RegisterForm!: FormGroup;
    maxFieldsets = 6;
    OTPtoMobile: string;checkNo: number; Mobilenum: string;
    mobiledata: any;classdata: any;classname: any;
    deptList: any;deptList1: any;StateId: any;
    DistrictName: any; flag: any; rolename: any; Districtdata: any;
    selectedDistrictName: any;Statedata: any;State: any = [];
    selectedStateName: any; selectedstateid: any;
    district: any = [];Citydata: any = [];
    Fdata: any; notice7: any; isUpdateMode: boolean = false;
    LegalNoticeID: number; cardNumber: any;
    Sname: any;ImageData: any; image: any;EImage: any; 
     WitnessSignature: any;
    imagefile2: any; ProfileImage: any;
    Profiledata: any; Profile: any; 
    PanDocDetails: any; PawnbrokerPhoto: any;  WittnessPhoto1: any;
    WittnessPhoto2: any; BarrowerPhoto: any; JewelleryPhoto1: any;
    JewelleryPhoto2: any; JewelleryPhoto3: any; JewelleryPhoto4: any;
    Barrowerdata: any; Pawnbrokerdata: any; Wittnessdata1: any;
    Wittnessdata2: any; Jewellerydata2: any; Jewellerydata1: any;
    Jewellerydata3: any; Jewellerydata4: any;
    RefNo: any;BorrowerName: any;
    BarrowerAddress: any; BarrowerMobile: any;
    FatherName: any; MonthlyIncome: any;
    RefNum: any; CreatedDate: any;Rupeesinwords: any;
    TotalAmount: any; LoanAmount: any;
    BarrowerOccupation: any; BarrowerAge: any;
    numberInput: any;
    words: any;

    constructor(
        public router: Router,
        private formBuilder: FormBuilder, public generalService: GeneralserviceService,
        public http: HttpClient, public activeroute: ActivatedRoute
    ) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.notice7 = localStorage.getItem("notice7");
        this.RefNo = localStorage.getItem("pawndocref");
        this.Wittnessdata1 = localStorage.getItem("Wittnessdata1");
        this.Barrowerdata = localStorage.getItem("Barrowerdata");
        this.Pawnbrokerdata = localStorage.getItem("Pawnbrokerdata");
        this.Wittnessdata2 = localStorage.getItem("Wittnessdata2");
        this.Jewellerydata1 = localStorage.getItem("Jewellerydata1");
        this.Jewellerydata2 = localStorage.getItem("Jewellerydata2");
        this.Jewellerydata3 = localStorage.getItem("Jewellerydata3");
        this.Jewellerydata4 = localStorage.getItem("Jewellerydata4");

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger

        if (!this.loginDet || !this.loginDet[0] || !this.loginDet[0].RegID) {
            this.router.navigate(['/Login']);
            return;
        }

        if (this.RefNo) {
            setTimeout(() => {
                localStorage.removeItem("pawndocref");
                console.log("pawndocref removed from localStorage");
            }, 300000); // 5 minutes in milliseconds
           // window.location.reload();
        }

        this.RegisterForm = this.formBuilder.group({
            BorrowerName: [''],
            FatherName: [''],
            BarrowerAddress: [''],
            BarrowerMobile: [''],
            BarrowerAge: [''],
            BarrowerOccupation: [''],
            MonthlyIncome: [''],
            TotalEstValue: [''],
            TotalGW: [''],
            TotalNW: [''],
            TotalAmount: ['',],
            Rupeesinwords: ['',],

        });



    }

       

    ngOnInit() {
        debugger
        this.GetPawnDocDetails();
        this.RegisterForm = this.formBuilder.group({
            ornaments: this.formBuilder.array([this.createFieldset()])
        });

        this.RegisterForm = this.formBuilder.group({
            BorrowerName: [''],
            FatherName: [''],
            BarrowerAddress: [''],
            BarrowerMobile: [''],
            BarrowerAge: [''],
            BarrowerOccupation: [''],
            MonthlyIncome:[''],
            TotalEstValue:[''],
            TotalGW:[''],
            TotalNW:[''],
            TotalAmount: ['',],
            Rupeesinwords: ['',],

            ornaments: this.formBuilder.array([this.createFieldset()])
        });
       

    }
  

    get ornaments(): FormArray {
        return this.RegisterForm.get('ornaments') as FormArray;
    }


    createFieldset(): FormGroup {
        return this.formBuilder.group({
            DescriptionOfArticlesPledged: ['', ],
            GrossWeight: ['', ],
            NetWeight: ['',],
            EstimeValue: ['', ],
            LoanAmount: ['',],
          //  TotalAmount: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],         
            MonthlyIncome: ['', ],

        });
    }

    GetPawnDocDetails() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.RefNo);
        var url = "api/FinancierLaw/GetxmlPanwbrokerDetails";
        this.generalService.Postdata1(url, uploadfile).subscribe(data => {
            debugger
            this.PanDocDetails = data;
            if (this.PanDocDetails && this.PanDocDetails.length > 0) {
                this.RegisterForm.patchValue({
                    BorrowerName: this.PanDocDetails[0].BorrowerName,
                    BarrowerAddress: this.PanDocDetails[0].BarrowerAddress,
                    BarrowerMobile: this.PanDocDetails[0].BarrowerMobile,
                    FatherName: this.PanDocDetails[0].FatherName,
                    BarrowerAge: this.PanDocDetails[0].BarrowerAge,
                    BarrowerOccupation: this.PanDocDetails[0].BarrowerOccupation,
                    MonthlyIncome: this.PanDocDetails[0].MonthlyIncome,
                    Rupeesinwords: this.PanDocDetails[0].Rupeesinwords,
                    LoanAmount: this.PanDocDetails[0].XLoanAmount,
                    TotalAmount: this.PanDocDetails[0].TotalAmount,
                    RefNum: this.PanDocDetails[0].RefNo,
                    //  BarrowerPhoto: `${this.HomeUrl}${this.PanDocDetails[0].BarrowerPhoto}`, // Concatenated URL,                   
                    GrossWeight: this.PanDocDetails[0].GrossWeight,
                    NetWeight: this.PanDocDetails[0].NetWeight,
                    EstimeValue: this.PanDocDetails[0].EstimeValue,
                    TotalGW: this.PanDocDetails[0].TotalGW,
                    TotalNW: this.PanDocDetails[0].TotalNW,
                    TotalEstValue: this.PanDocDetails[0].TotalEstValue,
                    CreatedDate: this.PanDocDetails[0].CreatedDate,

                })
                    
                const ornamentsArray = this.RegisterForm.get('ornaments') as FormArray;
                ornamentsArray.clear();
                // Add new ornaments data
                this.PanDocDetails.forEach((detail: any) => {
                    ornamentsArray.push(this.createOrnament(detail));
                });
                  
            }
               this.BarrowerPhoto = this.HomeUrl + this.PanDocDetails[0].BarrowerPhoto,
               this.PawnbrokerPhoto = this.HomeUrl + this.PanDocDetails[0].PawnbrokerPhoto,
               this.WittnessPhoto1 = this.HomeUrl + this.PanDocDetails[0].WittnessPhoto1,
               this.WittnessPhoto2 = this.HomeUrl + this.PanDocDetails[0].WittnessPhoto2,
               this.JewelleryPhoto1 = this.HomeUrl + this.PanDocDetails[0].JewelleryPhoto1,
               this.JewelleryPhoto2 = this.HomeUrl + this.PanDocDetails[0].JewelleryPhoto2,
               this.JewelleryPhoto3 = this.HomeUrl + this.PanDocDetails[0].JewelleryPhoto3,
               this.JewelleryPhoto4 = this.HomeUrl + this.PanDocDetails[0].JewelleryPhoto4      
        },error => {

        })

    }

    // Helper function to create an ornament FormGroup
    createOrnament(detail: any): FormGroup {
        return new FormGroup({
            DescriptionOfArticlesPledged: new FormControl(detail.DescriptionOfArticlesPledged || ''),
            GrossWeight: new FormControl(detail.GrossWeight || ''),
            NetWeight: new FormControl(detail.NetWeight || ''),
            EstimeValue: new FormControl(detail.EstimeValue || ''),
            LoanAmount: new FormControl(detail.XLoanAmount || '')
        });
    }

    addFieldset(): void {
        debugger
      //  this.ornaments.push(this.createFieldset());
        if (this.ornaments.length < this.maxFieldsets) {
            this.Fbdata = this.ornaments.value           
            this.ornaments.push(this.createFieldset());
        } else {
            alert('You can only add up to 6 Ornaments.take new form for more fileds.');
        }
    }
    sum() {
        this.Fbdata = this.ornaments.value
        this.calculateTotals();
    }
    removeFieldset(index: number): void {
        if (this.ornaments.length > 1) {
            this.ornaments.removeAt(index);
             this.Fbdata = this.ornaments.value
            this.calculateTotals();
        }
    }
    calculateTotals() {
        debugger
        const totalGW = this.Fbdata.reduce((acc, item) => acc + (item.GrossWeight || 0), 0);
        const totalNW = this.Fbdata.reduce((acc, item) => acc + (item.NetWeight || 0), 0);
        const totalEstValue = this.Fbdata.reduce((acc, item) => acc + (item.EstimeValue || 0), 0);
        const totalAmount = this.Fbdata.reduce((acc, item) => acc + (item.LoanAmount || 0), 0);
        this.convertToWords(totalAmount)
        // Bind values to the form
        this.RegisterForm.patchValue({
            TotalGW: totalGW,
            TotalNW: totalNW,
            TotalEstValue: totalEstValue,
            TotalAmount: totalAmount,
            //Rupeesinwords: this.convertToWords(totalAmount)
        });
    }
    //convertToWords(num: number): string {
    //    if (num === 0) return 'Zero';

    //    const units = [
    //        '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'
    //    ];
    //    const teens = [
    //        'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    //    ];
    //    const tens = [
    //        '', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    //    ];
    //    const thousands = ['Thousand', 'Lakh', 'Crore'];

    //    const getWords = (n: number): string => {
    //        if (n < 10) return units[n];
    //        if (n >= 11 && n <= 19) return teens[n - 11];
    //        if (n >= 10 && n < 100) {
    //            const tensPart = Math.floor(n / 10);
    //            const unitPart = n % 10;
    //            return tens[tensPart] + (unitPart ? ' ' + units[unitPart] : '');
    //        }
    //        if (n >= 100 && n < 1000) {
    //            const hundred = Math.floor(n / 100);
    //            const remaining = n % 100;
    //            return units[hundred] + ' Hundred' + (remaining ? ' and ' + getWords(remaining) : '');
    //        }
    //        return '';
    //    };

    //    let word = '';
    //    let place = 0;

    //    while (num > 0) {
    //        const part = num % 1000; // Break number into groups of three
    //        if (part > 0) {
    //            const placeWord = place > 0 ? thousands[place - 1] : '';
    //            word = getWords(part) + (placeWord ? ' ' + placeWord : '') + ' ' + word;
    //        }
    //        num = Math.floor(num / 1000);
    //        place++;
    //    }

    //    return word.trim();
    //}


    convertToWords(rupees: any) {
        debugger
        this.numberInput = rupees
        const a = [
            '', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ',
            'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ',
            'seventeen ', 'eighteen ', 'nineteen '
        ];
        const b = ['', '', 'twenty ', 'thirty ', 'forty ', 'fifty ', 'sixty ', 'seventy ', 'eighty ', 'ninety '];

        if (!this.numberInput || isNaN(Number(this.numberInput)) || this.numberInput.length > 9) {
            this.words = 'Invalid input or overflow';
            return;
        }

        const num = ('000000000' + this.numberInput).substr(-9); // Ensure the number is 9 digits
        const crorePart = this.getWord(num.substr(0, 2), a, b) + (num.substr(0, 2) !== '00' ? 'crore ' : '');
        const lakhPart = this.getWord(num.substr(2, 2), a, b) + (num.substr(2, 2) !== '00' ? 'lakh ' : '');
        const thousandPart = this.getWord(num.substr(4, 2), a, b) + (num.substr(4, 2) !== '00' ? 'thousand ' : '');
        const hundredPart = this.getWord(num.substr(6, 1), a, b) + (num.substr(6, 1) !== '0' ? 'hundred ' : '');
        const lastPart = this.getWord(num.substr(7, 2), a, b);

        this.words =
            crorePart +
            lakhPart +
            thousandPart +
            hundredPart +
            (lastPart !== '' ? 'and ' + lastPart : '') +
            'Rupees Only /-.';
        this.Rupeesinwords = this.words
        this.RegisterForm.controls['Rupeesinwords'].setValue(this.words);

        console.log('rs', this.words)
    }

    private getWord(num: string, a: string[], b: string[]): string {
        const n = parseInt(num, 10);
        if (n < 20) {
            return a[n];
        }
        const tens = Math.floor(n / 10);
        const units = n % 10;
        return b[tens] + (units ? a[units] : '');
    }



    Insert(formValue:any) {
        debugger
        if (!this.Barrowerdata || !this.Pawnbrokerdata 
            || !formValue.BarrowerAddress || !formValue.BarrowerMobile || !formValue.FatherName
            || !formValue.BorrowerName || !formValue.BarrowerOccupation) {
            alert('please give all mandatory fields');
            return;
        }
        const convertToBase64 = (filePath: File): Promise<string> => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = error => reject(error);
                reader.readAsDataURL(filePath);
            });
        };
        var borrowerDetails = {
            RegID: this.loginDet[0].RegID,
            BorrowerName: formValue.BorrowerName,
            FatherName: formValue.FatherName,
            BarrowerAddress: formValue.BarrowerAddress,
            BarrowerMobile: formValue.BarrowerMobile,
            MonthlyIncome: formValue?.MonthlyIncome || 0,
            BarrowerAge: formValue.BarrowerAge,
            BarrowerOccupation: formValue.BarrowerOccupation,
            TotalGW: formValue?.TotalGW || 0,
            TotalNW: formValue?.TotalNW || 0,
            TotalEstValue: formValue?.TotalEstValue || 0,
            LoanAmount: 0,
            TotalAmount: formValue?.TotalAmount || 0,
            Rupeesinwords: formValue.Rupeesinwords,
            BarrowerPhoto: this.Barrowerdata,
            PawnbrokerPhoto: this.Pawnbrokerdata,
            WittnessPhoto1: this.Wittnessdata1,
            WittnessPhoto2: this.Wittnessdata2,
            JewelleryPhoto1: this.Jewellerydata1,
            JewelleryPhoto2: this.Jewellerydata2,
            JewelleryPhoto3: this.Jewellerydata3,
            JewelleryPhoto4: this.Jewellerydata4,
        };

        var ornaments = formValue.ornaments.map((ornament: any) => ({
            DescriptionOfArticlesPledged: ornament.DescriptionOfArticlesPledged,
            GrossWeight: ornament.GrossWeight,
            NetWeight: ornament.NetWeight,
            EstimeValue: ornament.EstimeValue,          
            LoanAmount:ornament.LoanAmount,
            TotalAmount:0,
            TotalGW: 0, TotalNW:0,
            TotalEstValue:0,

        }));

        this.arr = [borrowerDetails];
       // this.arr.push(borrowerDetails);
        this.arr1 = ornaments;
        //this.arr1 = [];
        //this.arr1.push(...ornaments);
        const uploadfile = new FormData();
        uploadfile.append("Param1", JSON.stringify(this.arr));
        uploadfile.append("Param2", JSON.stringify(this.arr1));
        uploadfile.append("Flag", '1');
        const url = 'api/FinancierLaw/Insert_panborker_ProductDetails';
        this.generalService.PostData2(url, uploadfile).then((data: any) => {
            debugger
            if (data) {
                alert("Your Form ADDED successfully");
                this.router.navigate(['/pawndocumentform']);
                localStorage.setItem('pawndocref',data)

            } else {
                alert("YSome thing went wrong");
            }
        });
    }
    update(formValue: any) {
    debugger
        if (!this.Barrowerdata || !this.Pawnbrokerdata || !this.Wittnessdata1 || !this.Wittnessdata2
            ) {
            alert('please give all mandatory fields');
        }
        var borrowerDetails = {
                 RefNo: this.RefNo,
                 RegID: this.loginDet[0].RegID,
                 BorrowerName: formValue.BorrowerName,
                 FatherName: formValue.FatherName,
                 BarrowerAddress: formValue.BarrowerAddress,
                 BarrowerMobile: formValue.BarrowerMobile,
                 MonthlyIncome: formValue ?.MonthlyIncome || 0,
                 BarrowerAge: formValue.BarrowerAge,
                 BarrowerOccupation: formValue.BarrowerOccupation,
                 TotalGW: formValue ?.TotalGW || 0,
                 TotalNW: formValue ?.TotalNW || 0,
                 TotalEstValue: formValue ?.TotalEstValue || 0,
                 LoanAmount: 0,
                 TotalAmount: formValue ?.TotalAmount || 0,
                 Rupeesinwords: formValue.Rupeesinwords,
                 BarrowerPhoto: this.Barrowerdata,
                 PawnbrokerPhoto: this.Pawnbrokerdata,
                 WittnessPhoto1: this.Wittnessdata1,
                 WittnessPhoto2: this.Wittnessdata2,
                 JewelleryPhoto1: this.Jewellerydata1,
                 JewelleryPhoto2: this.Jewellerydata2,
                 JewelleryPhoto3: this.Jewellerydata3,
                 JewelleryPhoto4: this.Jewellerydata4,
          };

        var ornaments = formValue.ornaments.map((ornament: any) => ({
            DescriptionOfArticlesPledged: ornament.DescriptionOfArticlesPledged,
            GrossWeight: ornament.GrossWeight,
            NetWeight: ornament.NetWeight,
            EstimeValue: ornament.EstimeValue,
            LoanAmount: ornament.LoanAmount,
            TotalAmount: 0,
            TotalGW: 0, TotalNW: 0,
            TotalEstValue: 0,
        
        }));

          this.arr = [borrowerDetails];
          // this.arr.push(borrowerDetails);
          this.arr1 = ornaments;
          //this.arr1 = [];
          //this.arr1.push(...ornaments);
          const uploadfile = new FormData();
          uploadfile.append("Param1", JSON.stringify(this.arr));
          uploadfile.append("Param2", JSON.stringify(this.arr1));
          uploadfile.append("Flag", '2');
          const url = 'api/FinancierLaw/Insert_panborker_ProductDetails';
          this.generalService.PostData2(url, uploadfile).then((data: any) => {
              debugger
              if (data) {
                  alert("Your Form ADDED successfully");
                  this.router.navigate(['/pawndocumentform']);
                  localStorage.setItem('pawndocref', data)
          
              } else {
                  alert("YSome thing went wrong");
              }
          });
     }



    reg() {
        if (!this.LoginDetails || Object.keys(this.LoginDetails).length === 0) {
            this.router.navigate(['/Login']);
            return;
        } else {
            this.router.navigate(['/Home']).then(() => window.location.reload());
        }
    }
   
    //This is for Uploading Multiple Images
    Borrower(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                debugger
                if (data != null) {
                    this.BarrowerPhoto = this.HomeUrl + data;
                    this.Barrowerdata = data;
                    localStorage.setItem('Barrowerdata', this.Barrowerdata);
                }
            });
        }
    }

    Pawnbroker(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                debugger
                if (data != null) {
                    this.PawnbrokerPhoto = this.HomeUrl + data;
                    this.Pawnbrokerdata = data;
                    localStorage.setItem('Pawnbrokerdata', this.Pawnbrokerdata);
                }
            });
        }
    }

    witness1(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                debugger
                if (data != null) {
                    this.WittnessPhoto1 = this.HomeUrl + data;
                    this.Wittnessdata1 = data;
                    localStorage.setItem('Wittnessdata1', this.Wittnessdata1);
                }
            });
        }
    }

    witness2(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                debugger
                if (data != null) {
                    this.WittnessPhoto2 = this.HomeUrl + data;
                    this.Wittnessdata2 = data;
                    localStorage.setItem('Wittnessdata2', this.Wittnessdata2);

                }
            });
        }
    }

    jewell1(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                debugger
                if (data != null) {
                    this.JewelleryPhoto1 = this.HomeUrl + data;
                    this.Jewellerydata1 = data;
                    localStorage.setItem('Jewellerydata1', this.Jewellerydata1);

                }
            });
        }
    }

    jewell2(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                debugger
                if (data != null) {
                    this.JewelleryPhoto2 = this.HomeUrl + data;
                    this.Jewellerydata2 = data;
                    localStorage.setItem('Jewellerydata2', this.Jewellerydata2);
                }
            });
        }
    }

    jewell3(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                debugger
                if (data != null) {
                    this.JewelleryPhoto3 = this.HomeUrl + data;
                    this.Jewellerydata3 = data;
                    localStorage.setItem('Jewellerydata3', this.Jewellerydata3);

                }
            });
        }
    }


    jewell4(event) {
        debugger
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/FinancierLaw/Post_Images';
            this.generalService.PostData(url, frmData).subscribe((data: any) => {
                debugger
                if (data != null) {
                    this.JewelleryPhoto4 = this.HomeUrl + data;
                    this.Jewellerydata4 = data;
                    localStorage.setItem('Jewellerydata4', this.Jewellerydata4);

                }
            });
        }
    }


}
