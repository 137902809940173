<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Loan Rescheduling Request Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white">Loan Rescheduling Request Form</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>


                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">
                            <!--<div class="col-lg-4">
        <div class="form-group">

            <label for="AuctionedDate">AuctionedDate</label>
            <input type="date" formControlName="AuctionedDate" placeholder="Enter Your AuctionedDate" id="AuctionedDate" required>
        </div>
    </div>-->

                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Financer Name*</label>
                                    <input type="text" formControlName="FinancerName" placeholder="Enter Your Financer Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Financer Address*</label>
                                    <input type="text" formControlName="FinancerAddress" placeholder="Enter Your Financer Address" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>-->
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Name*</label>
                                    <input type="text" formControlName="Name" placeholder="Enter Your Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Father Name*</label>
                                    <input type="text" formControlName="FatherName" placeholder="Enter Your Father Name" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Address*</label>
                                    <input type="text" formControlName="Address" placeholder="Enter Your Address" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Mobile*</label>
                                    <input type="text" formControlName="Mobile" onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" minlength="10" placeholder="Enter Your Mobile" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Mobile">Age*</label>
                                    <input type="text" formControlName="Age" onkeypress="return /[0-9]/i.test(event.key)" maxlength="2" placeholder="Enter Your Age" inputmode="numeric" maxlength="50" required>
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Occupation*</label>
                                    <input type="text" formControlName="Occupation" placeholder="Enter Your Occupation" id="Occupation" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleRegNo">Vehicle RegNo*</label>
                                    <input type="text"
                                           formControlName="VehicleRegno"
                                           placeholder="Enter Your Vehicle RegNo"
                                           id="VehicleRegno"
                                           required
                                           (keyup)="convertToUppercase()">
                                </div>
                            </div>


                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="BarrowerAddress">Vehicle make</label>
                                    <input type="text" formControlName="Vehiclemake" placeholder="Enter Your Vehicle make" id="Vehiclemake" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="Agreementdate"> Agreement date*</label>
                                    <input type="date" formControlName="Agreementdate" placeholder="DD/MM/YYYY" id="Agreementdate" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="TotalAmountwInterest">Total Amount w/Interest</label>
                                    <input type="text" formControlName="TotalAmountwInterest" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Your Total Amount w/interest" id="LoanAmount" required>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="TotalAmount">Total Paid Amount</label>
                                    <input type="text" formControlName="TotalAmount" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Your Total Amount" id="DueAmount" required>
                                </div>
                            </div>
                               <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="Balance">Balance Amount</label>
                                    <input type="text" formControlName="Balance" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Your Balance" id="DueAmount" required>
                                </div>
                            </div>
                             <div class="col-lg-4">
                                <div class="form-group">

                                    <label for="RepayingAmount">Fresh Finance Amount</label>
                                    <input type="text" formControlName="RepayingAmount" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Fresh Finance Amount" id="DueAmount" required>
                                </div>
                            </div>
                             <!--<div class="col-lg-4">
                                <div class="form-group">

                                    <label for="RescheduleAmount">Reschedule Amount</label>
                                    <input type="text" formControlName="RescheduleAmount" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Your RescheduleAmount" id="DueAmount" required>
                                </div>
                            </div>-->
                            
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Surety Name*</label>
                                    <input type="text" formControlName="SuretyName" placeholder="Enter Your Surety Name" id="SuretyName" required>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="SuretyMobile">Surety Mobile</label>
                                    <input type="text"
                                           formControlName="Suretymobile"
                                           maxlength="10"
                                           placeholder="Enter Your Surety Mobile"
                                           id="SuretyMobile"
                                           required
                                           pattern="[0-9]*"
                                           onkeypress="return /[0-9]/i.test(event.key)">
                                </div>
                            </div>



                            <!--<div class="col-lg-4">
                                <div class="form-group">
                                    <label for="TransportType">Wheel Type*</label>
                                    <select formControlName="wheeltype" required style="width: 100%;">
                                        <option value="" disabled>Select Wheel Type</option>
                                        <option value="2 wheeler">2 wheeler</option>
                                        <option value="3 wheeler">3 wheeler</option>
                                        <option value="4 wheeler">4 wheeler</option>
                                    </select>
                                </div>
                            </div>-->


                            <div class="form-group">
                                <!-- If AgreementNo is null, undefined, or empty, show Submit button -->
                                <button *ngIf="aggrementNo===null || aggrementNo=== undefined" class="btn" (click)="getRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>

                                <!-- If AgreementNo has a value, show Update button -->
                                <button *ngIf="aggrementNo !==null" class="btn" (click)="updateRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>
                            </div>



                            <!--<div class="form-group" (click)="getRegistration(RegisterForm.value)">

        <button class="btn">
            <b>Submit</b>
        </button>

    </div>-->
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</div>

