<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Forgot Password
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Forgot Password</li>
            </ol>
        </div>
    </div>

    <div class="container">
        <div class="row justify-content-center mt-5">
            <div class="col-lg-12">
                <div class="card">
                    <div class="row no-gutters">
                        <!-- Image half -->
                        <div class="col-lg-6">
                            <!--<img src="../../assets/img/aboutt.jpg" class="card-img" alt="Image" />-->
                            <img src="../../../assets/img/resetpasswordicon13.jpg" class="card-img" alt="Image" />
                        </div>
                        <!-- Login Form half -->
                        <div class="col-lg-6 d-flex align-items-center">
                            <div class="card-body">

                                <form >
                                    <div class="form-group">
                                        <label for="mobile">Mobile</label>
                                        <input [(ngModel)]="Mobile" type="text" placeholder="Enter Your Mobile"
                                               id="username" name="username" inputmode="numeric"
                                               class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <label for="Password">Password</label>
                                        <div class="input-container">
                                            <input [type]="showPassword ? 'text' : 'password'"
                                                   [(ngModel)]="Password"
                                                   placeholder="Password"
                                                   id="password"
                                                   name="Password"
                                                   class="form-control">
                                            <span class="input-icon" (click)="togglePasswordVisibility()">
                                                <i class="fa" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                                            </span>
                                        </div>
                                    </div>



                                    <div class="col-lg-12">
                                        <div class="row">
                                            <!--<div class="col-lg-5" (click)="Update()">
                                                <a class="text-muted">Submit</a>
                                            </div>-->
                                            <div class="col-lg-6" (click)="Update()">
                                                <button class="btn btn-primary" type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

