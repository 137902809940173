<div class="container">
    <div id="pdf-content">
        <br />
        <div class="text-center">
            <div>
                <h1 class="mtb-2">{{logindetails[0].CompanyName | uppercase }}</h1>
                <p>{{logindetails[0].CompanyAddress}}{{logindetails[0].DistrictName}},{{logindetails[0].StateName}}</p>
                <p>{{logindetails[0].Mobile}}</p>
            </div><hr />
        </div>
        <div>
            <h6 class="text-center"><b> PAWN DOCUMENT FORM </b></h6>
        </div>   <br>
        <div class="row">
            <div class="col-lg-9">
                Ref No : <b>{{RefNum}}</b>,
            </div>

            <div class="col-lg-3">
                <div class="form-group">
                    <p class="right">Date : <b>{{CreatedDate | date:'dd-MM-yyyy'}}</b></p>
                </div>
            </div>
        </div><br />
        <div class="row">
            <div class="col-md-10">
                <div class="line">
                    <p class="left">
                        Name : <b>{{BorrowerName}}</b>&nbsp;,s/w/d of : <b>{{FatherName}}</b>,&nbsp;
                        Age:<b>{{BarrowerAge}}</b>&nbsp;,Occ:<b>{{BarrowerOccupation}}</b>&nbsp;
                        Address : <b>{{BarrowerAddress}}</b>&nbsp;,Phone No : <b>{{BarrowerMobile}},</b>&nbsp;
                        Montly income : ₹ <b> {{MonthlyIncome}}/-</b>
                    </p>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div><br /><br />

        <table class="table table-bordered ">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Description of Articles pledged</th>
                    <th>GW(Gms)</th>
                    <th>NW(Gms)</th>
                    <th>Est-Value(₹)</th>
                    <th>LOAN Amt(₹)</th>
                </tr>
            </thead>
            <tbody *ngFor="let item of PanDocDetails; let i = index">
                <tr>
                    <td>{{ i + 1 }}</td>
                    <td>{{item.DescriptionOfArticlesPledged}}</td>
                    <td>{{item.GrossWeight}}</td>
                    <td>{{item.NetWeight}}</td>
                    <td style="text-align:right">{{item.EstimeValue}}/-</td>
                    <td style="text-align:right">{{item.XLoanAmount}}/-</td>
                </tr>

            </tbody>

            <tfoot>
                <tr>
                    <td></td>
                    <td class="text-right"><b>Total</b></td>
                    <td><b>{{TotalGW}}</b></td>
                    <td><b>{{TotalNW}}</b></td>
                    <td style="text-align:right"><b>{{TotalEstValue}}/-</b></td>
                    <td style="text-align:right"><b>{{TotalAmount}}/-</b></td>
                </tr>
            </tfoot>
        </table>
        <br /><br /><br />
        <p>
            <b>Total Loan Amount</b>: {{TotalAmount}}<br />
            <b>Total Loan Amount In Words</b>: {{Rupeesinwords}}
        </p><br />
        <!--card mt-xxl-5-->
        <p class="mb-5 border p-3 rounded">
            INTEREST SHOULD BE PAID EVERY 3 MONTHS WITHOUT FAIL OTHERWISE, COMPOUND INTEREST
            WILL BE CHARGED. ORNAMENTS PLEDGED SHOULD BE REDEEMED BACK WITHIN MONTHS OR
            ELSE. THE ORNAMENTS WILL BE SOLD WITHOUT ANY NOTICE. Interest is to be paid every
            three months.
        </p> <br /> <br /><br /><br />
        <p class="signature-lines">
            <span class="left-signature">Signature or L.T.I. of Pawner</span>
            <span class="right-signature">Signature of Pawn Broker or Agent</span>
        </p><br /><br /><br />

        <div class="image-row">
            <div class="image-container">
                <p>Borrower Photo</p>
                <img src="{{HomeUrl + BarrowerPhoto}}" />
            </div>
            <div class="image-container">
                <p>Pawnbroker Photo</p>
                <img src="{{HomeUrl + PawnbrokerPhoto}}" />
            </div>
            <div class="image-container">
                <p>Witness Photo1</p>
                <img src="{{HomeUrl + WittnessPhoto1}}" />
            </div>
            <div class="image-container">
                <p>Witness Photo2</p>
                <img src="{{HomeUrl + WittnessPhoto2}}" />
            </div>
        </div> <br />

        <div class="image-row">
            <div class="image-container">
                <p>Jewellery Photo1</p>
                <img src="{{HomeUrl + JewelleryPhoto1}}" />
            </div>
            <div class="image-container">
                <p>Jewellery Photo2</p>
                <img src="{{HomeUrl + JewelleryPhoto2}}" />
            </div>
            <div class="image-container">
                <p>Jewellery Photo3</p>
                <img src="{{HomeUrl + JewelleryPhoto3}}" />
            </div>
            <div class="image-container">
                <p>Jewellery Photo4</p>
                <img src="{{HomeUrl + JewelleryPhoto4}}" />
            </div>
        </div>
        <br /><br />
        <div style="text-align:right">PTO</div>
        <br /><br /><br /><br /><br /><br /><br /><br />
        <div class="terms">
            <p style="text-align:center">PART-2</p>
            <h3> Terms & Conditions : </h3>
            <br />
            <p class="mb-1">1. The postal address given overleaf is correct.  </p>
            <p class="mb-1">2. That i will inform the pawnee forthwith of any change in my address. </p>
            <p class="mb-1">3. That the maximum period, available for redemption is 11 months from the date of pledge as agreed to between the parties.</p>
            <p class="mb-1">4. That i am bound to preserve the token issued to me by the pawnee in respect of the said commodities. </p>
            <p class="mb-1">5. That i understand that the said commodities listed overleaf will not returned to me unless produce the said token. </p>
            <p class="mb-1">6. That in case the token is lost i am entitled to the issue of another token on submission of letter and on payment of sum Rs.5/-. </p>
            <p class="mb-1">7. That on expiry of the aforesaid period of redemption the pawnee is at liberty to self / auction the said commodities & shall cease to have any claim what so ever over the same.</p>
            <p class="mb-1">8. That i am bound to give 24 hours prior notice to the pawnee for release of the said commodities after pay in all amounts due to the pawnee towards amount borrowed, interest accrued there on together with legal / miscellaneous expenses incurred, if any. </p>
            <p class="mb-1">9. That in case the said commodities listed overleaf are lost for any reason what so ever, I shall be entitled to receive only the sums specified against item No.7(3) overleaf towards their value.</p>
            <p class="mb-1">10. That the commodities listed overleaf are my own and no other person has got any right the same. </p>
            <p class="mb-1">11. That on expiry of the aforesaid period of redemption the pawnee is at liberty to self / auction the said commodities & shall cease to have any claim what so ever over the same.</p>
            <p class="mb-1">12. That i hereby undertake to abide by the aforsaid terms conditions and stipulations.</p>
        </div>
        <p>
            <b>NOTE:</b> These terms and conditions are agreed upon the pawner and pawnbroker.
            Any violation may result in legal proceedings as per local pawn and money lending laws.
        </p>
        <br /><br />
        <!--<br /><br /><br /><br />-->
        <!--<div class="col-lg-12">
        <div class="row">
            <div class="col-lg-3">
                <div>Seller Signature</div>
            </div>
            <div class="col-lg-3">
                <div>Buyyer Signature</div>
            </div>
            <div class="col-lg-3">
                <div>Witness Signature1</div>
            </div>
            <div class="col-lg-3">
                <div>Witness Signature2</div>
            </div>
        </div>
    </div>-->

        <br /><br /><br /><br /><br /><br />
        <p class="signature-line">
            <span class="left-signature">Signature or L.T.I of Pawner</span>
            <span class="right-signature">Money Lender / Agent</span>
        </p>
        <span class="left-signature">Date : <b>{{CreatedDate | date:'dd-MM-yyyy'}}</b></span><br />
        <div style="text-align:center">
            <u><b>RECEIPT</b></u>
        </div>
        <div class="terms">
            <p class="mb-1">Paid Rs............................. and redeemed the articles mentioned in the Receipt Overleaf. </p>
            <p>Date: ................................</p>
        </div>
        <br /><br />
        <p class="signature-line">
            <span class="left-signature">Signature or L.T.I of Pawner</span>
            <span class="right-signature">Money Lender / Agent</span>
        </p>
    </div>
</div>
<br />
    <div class="row">
        <div class="col-lg-8 ">
        </div>
        <div class="col-lg-1 ">
            <!--<button class="pdf" (click)="updatepawn(RefNum)"><i class="fa fa-edit" ></i> Edit  </button>-->
        </div>
        <div class="col-lg-3">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF  </button>
        </div>
    </div>

