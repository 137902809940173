<!--<div class="container mtb-100">
    <div class="row">
        <div class="col-lg-6">
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                        <div class="form-group">
                            <label for="mobile">Mobile/Email</label>
                            <input formControlName="Mobile/EMail" type="text" placeholder="Enter Your Mobile/Email"
                                   id="username" name="username" inputmode="numeric"
                                   class="form-control">
                        </div>

                        <div class="form-group">
                            <label for="Password">Password*</label>
                            <input formControlName="Password" type="password" placeholder="Password"
                                   id="password" name="Password" class="form-control">
                        </div>
                        <div class="form-group d-flex justify-content-between align-items-center">
                            <div>
                                <div>
                                    <a href="/ForgotPassword" class="text-muted">Forgot password?</a>
                                </div><br />
                                <button class="btn btn-primary" type="submit">Login</button>
                            </div>
                            <div>
                                <a href="/RoleSelection">
                                    <div class="button">
                                        <b>Sign Up</b>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>-->
<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Login
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Login</li>
            </ol>
        </div>
    </div>

    <div class="container">
        <div class="row justify-content-center mt-5">
            <div class="col-lg-12">
                <div class="card">
                    <div class="row no-gutters">
                        <!-- Image half -->
                        <div class="col-lg-6">
                            <img src="../../assets/img/aboutt.jpg" class="card-img" alt="Image" />
                        </div>
                        <!-- Login Form half -->
                        <div class="col-lg-6 d-flex align-items-center">
                            <div class="card-body">

                                <form [formGroup]="loginForm" (ngSubmit)="login()">
                                    <div class="form-group">
                                        <label for="mobile">Mobile/Email</label>
                                        <input formControlName="Mobile/EMail" type="text" placeholder="Enter Your Mobile/Email"
                                               id="username" name="username" inputmode="numeric"
                                               class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <label for="Password">Password*</label>
                                        <!--<div class="input-group">
        <input [type]="showPassword ? 'text' : 'password'"
               formControlName="Password"
               placeholder="Password"
               id="password"
               name="Password"
               class="form-control">
        <div class="input-group-append">
            <span class="input-group-text" (click)="togglePasswordVisibility()" style="cursor: pointer;">
                <i class="fa" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
            </span>
        </div>
    </div>-->
                                        <div class="input-container">
                                            <input [type]="showPassword ? 'text' : 'password'"
                                                   formControlName="Password"
                                                   placeholder="Password"
                                                   id="password"
                                                   name="Password"
                                                   class="form-control">
                                            <span class="input-icon" (click)="togglePasswordVisibility()">
                                                <i class="fa" [ngClass]="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                                            </span>
                                        </div>

                                    </div>



                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-7">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" [checked]="Remember" (change)="RememberMe($event)" class="custom-control-input" id="login-checkbox">&nbsp;
                                                    <label class="custom-control-label" for="login-checkbox">Keep me signed in.</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-5" (click)="forgott()">
                                                <a  class="text-muted">Forgot password?</a>
                                            </div>
                                        </div>
                                        <br />
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <button class="btn btn-primary" type="submit">Login</button>
                                            </div>
                                            <div class="col-lg-4 button">
                                                <a href="/RoleSelection" style="color:black">
                                                    Sign Up
                                                </a>
                                            </div>
                                            <div class="col-lg-2"></div>
                                        </div>

                                    </div>

                                </form>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- #region check otp popup for forgot password-->
    <!--<div class="modal" id="myModal3">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true">ÃƒÆ’Ã¢â‚¬â€</span></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>OTP<span style="color:red">*</span></label>
                        <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text" style="width:450px">
                    </div>
                    <div class="row" style="float:right;cursor:pointer;margin-right:10px">
                        <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
                    </div>
                </div>
                <div class="modal-footer">

                    <button id="closemodal" type="button" class="btn btn-danger" data-dismiss="modal" (click)="Close()">Close</button>
                    <button type="submit" class="btn btn-primary" (click)="VerifyOtp(OTP)">Submit</button>
                </div>
            </div>
        </div>
    </div>-->
    <!-- #endregion -->
