import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, FormControl } from '@angular/forms';
import * as html2pdf from 'html2pdf.js';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pawndocumentform',
  templateUrl: './pawndocumentform.component.html',
  styleUrl: './pawndocumentform.component.scss'
})
export class PawndocumentformComponent {
    RefNo: any;
    PanDocDetails: any;
    BorrowerName: any;
    BarrowerAddress: any; BarrowerMobile: any;
    FatherName: any; MonthlyIncome: any;
    RefNum: any; CreatedDate: any;
    HomeUrl: any; BarrowerPhoto: any;
    PawnbrokerPhoto: any; WittnessPhoto1: any;
    WittnessPhoto2: any; JewelleryPhoto1: any;
    JewelleryPhoto2: any; JewelleryPhoto3: any;
    JewelleryPhoto4: any; Rupeesinwords: any;
    TotalAmount: any; LoanAmount: any;
    logindetails: any; BarrowerAge: any;
    BarrowerOccupation: any; TotalNW: any;
    TotalGW: any; TotalEstValue: any;

    constructor(public router: Router, public http: HttpClient,
        private formBuilder: FormBuilder, public activeroute: ActivatedRoute,
        public generalService: GeneralserviceService,) {
        this.logindetails = JSON.parse(localStorage.getItem('LoginDetails'));
        this.RefNo = localStorage.getItem("pawndocref");
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
      
    }


    ngOnInit() {
      this.GetPawnDocDetails();

    }

    GetPawnDocDetails() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.RefNo);
        var url = "api/FinancierLaw/GetxmlPanwbrokerDetails";
        this.generalService.Postdata1(url, uploadfile).subscribe(data => {
            debugger
            this.PanDocDetails = data;
            this.BorrowerName = this.PanDocDetails[0].BorrowerName
            this.BarrowerAddress = this.PanDocDetails[0].BarrowerAddress
            this.BarrowerMobile = this.PanDocDetails[0].BarrowerMobile
            this.FatherName = this.PanDocDetails[0].FatherName
            this.BarrowerAge = this.PanDocDetails[0].BarrowerAge
            this.BarrowerOccupation = this.PanDocDetails[0].BarrowerOccupation
            this.MonthlyIncome = this.PanDocDetails[0].MonthlyIncome
            this.Rupeesinwords = this.PanDocDetails[0].Rupeesinwords
            this.LoanAmount = this.PanDocDetails[0].LoanAmount
            this.TotalEstValue = this.PanDocDetails[0].TotalEstValue
            this.TotalGW = this.PanDocDetails[0].TotalGW
            this.TotalNW = this.PanDocDetails[0].TotalNW
            this.TotalAmount = this.PanDocDetails[0].TotalAmount
            this.RefNum = this.PanDocDetails[0].RefNo
            this.BarrowerPhoto = this.PanDocDetails[0].BarrowerPhoto
            this.PawnbrokerPhoto = this.PanDocDetails[0].PawnbrokerPhoto
            this.WittnessPhoto1 = this.PanDocDetails[0].WittnessPhoto1
            this.WittnessPhoto2 = this.PanDocDetails[0].WittnessPhoto2
            this.JewelleryPhoto1 = this.PanDocDetails[0].JewelleryPhoto1
            this.JewelleryPhoto2 = this.PanDocDetails[0].JewelleryPhoto2
            this.JewelleryPhoto3 = this.PanDocDetails[0].JewelleryPhoto3
            this.JewelleryPhoto4 = this.PanDocDetails[0].JewelleryPhoto4
            this.CreatedDate = this.PanDocDetails[0].CreatedDate
          
        })

    }


    downloadPDF() {
        debugger
        const element = document.getElementById('pdf-content');
        localStorage.removeItem("pawndocref");
        localStorage.removeItem("Wittnessdata1"); localStorage.removeItem("Jewellerydata1"); 
        localStorage.removeItem("Wittnessdata2"); localStorage.removeItem("Pawnbrokerdata");
        localStorage.removeItem("Barrowerdata"); localStorage.removeItem("Jewellerydata2");
        localStorage.removeItem("Jewellerydata3"); localStorage.removeItem("Jewellerydata4");
     
        if (!element) {
            console.error('Element not found');
            return;
        }
          
        // Temporarily adjust styles for PDF generation
        const originalStyle = {
            fontSize: element.style.fontSize,
            lineHeight: element.style.lineHeight,
            color: element.style.color,
            padding: element.style.padding
        };

        element.style.fontSize = '12px'; // Adjust as needed
        element.style.lineHeight = '1'; // Adjust as needed
        element.style.color = '#0A0A0A'; // Enforce black text
        element.style.padding = '0'; // Remove padding if needed

        // Temporarily adjust paragraph spacing
        const paragraphs = element.getElementsByTagName('p');
        for (let i = 0; i < paragraphs.length; i++) {
            paragraphs[i].style.margin = '0'; // Remove margin
            paragraphs[i].style.padding = '0'; // Remove padding
            paragraphs[i].style.color = '#0A0A0A'; // Enforce black text for paragraphs
        }

        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const yyyy = today.getFullYear();
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const seconds = String(today.getSeconds()).padStart(2, '0');

        const dateTimeString = `${dd}-${mm}-${yyyy}-${hours}:${minutes}:${seconds}`;
        const filename = `PawnDocumentForm_${this.RefNum}.pdf`;

        // Example of directory path where the file might be saved (for demonstration)
        const filePath = `C:/Users/YourUsername/Downloads/${filename}`;

        // Set PDF options
        const options = {
            margin: [0, 0.5, 0.5, 0.5], // Adjust margins if needed
            filename: filename,
            image: { type: 'jpg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };

        // Convert HTML to PDF
        html2pdf()
            .from(element)
            .set(options)
            .save()
            .then(() => {
                // Revert the styles for the front-end
                element.style.fontSize = originalStyle.fontSize;
                element.style.lineHeight = originalStyle.lineHeight;
                element.style.color = originalStyle.color;
                element.style.padding = originalStyle.padding;

                // Revert paragraph spacing
                for (let i = 0; i < paragraphs.length; i++) {
                    paragraphs[i].style.margin = ''; // Revert margin
                    paragraphs[i].style.color = ''; // Reset text color
                    paragraphs[i].style.padding = ''; // Revert padding
                }
                alert('Your pdf has been downloaded successfully to your downloads folder.');
            });

        html2pdf().from(element).set(options).toPdf().outputPdf('blob').then((pdfBlob) => {
            // Create a file-like object from the Blob
            const file = new File([pdfBlob], filename, { type: 'application/pdf' });
            //  this.autoUpload(file);

            // Output the file path
            console.log(`PDF saved at: ${filePath}`);
            this.router.navigate(['/'])
        });
    }

    updatepawn(RefNum:any) {
        this.router.navigate(['/Pawndocument'], { queryParams: { RefNum: RefNum } } )
    }


}
