<div class="ptb-100">
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center ">
            <h6 class="text-white display-3 mb-4 wow fadeInDown" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInDown;">
                Recovery Manager Form
            </h6>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInDown;">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active text-white"> Recovery Manager Form</li>
            </ol>
        </div>
    </div>
    <div class="container" style="margin-top: 2%;margin-bottom: 15px;">
        <div class="row">
            <div class="col-12">
                <fieldset>
                    <form [formGroup]="RegisterForm">
                        <div class="row p-5">


                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="RecoveryManagerName">Recovery Manager Name <span style="color:red">*</span></label>
                                    <input type="text" formControlName="RecovererName" placeholder="Enter Recovery Manager Name" id="BorrowerName" required>
                                </div>
                                <br />
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="Recovery S/o">Recovery Father Name <span style="color:red">*</span></label>
                                    <input type="text" formControlName="RecovererSonOf" placeholder="Enter Recovery Father Name" id="BarrowerAddress" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
        <div class="form-group">
            <label for="Recoverer Mobile">Recovery Occupation <span style="color:red">*</span></label>
            <input type="text" formControlName="RecovererOcc" placeholder="Enter Your Recovery Occupation"  required>
            <br />
        </div>
    </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="RecoveryAge">Recovery Age <span style="color:red">*</span></label>
                                    <input type="text" formControlName="RecovererAge" placeholder="Enter Recovery Age" onkeypress="return /[0-9]/i.test(event.key)" maxlength="2" minlength="2" id="LoanAmount" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleRegNo">Vehicle RegNo <span style="color:red">*</span></label>
                                    <input type="text"
                                           formControlName="VehicleRegNo"
                                           placeholder="Enter Your Vehicle RegNo"
                                           maxlength="10" minlength="9"
                                           id="VehicleRegNo"
                                           required
                                           (keyup)="convertToUppercase()">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="VehicleType">Class of Vehicle*</label>
                                    <select formControlName="VehicleType" required style="    width: 100%;">
                                        <option value="" disabled selected>Select Vehicle Type</option>
                                        <option value="Car">Car</option>
                                        <option value="Bike">Bike</option>
                                        <option value="Auto">Auto</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4" *ngIf="RegisterForm.get('VehicleType').value === 'Other'">
                                <div class="form-group">
                                    <label for="VehicleTypes">Vehicle Name*</label>
                                    <input type="text" [(ngModel)]="VehicleType1" [ngModelOptions]="{standalone: true}" placeholder="Enter Class of Vehicle" required>
                                </div>
                            </div>
                            

                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BorrowerName">Barrower Name <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerName" placeholder="Enter Barrower Name" id="LoanAmount" required (keyup)="convertToUppercase()">
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerFatherName">Barrower Father Name <span style="color:red">*</span></label>
                                    <input type="text" formControlName="FatherName" placeholder="Enter Barrower Father Name" id="LoanAmount" required (keyup)="convertToUppercase()">
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAge">Barrower Age <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerAge" placeholder="Enter Barrower Age" onkeypress="return /[0-9]/i.test(event.key)" maxlength="2" minlength="2" id="LoanAmount" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerOccupation">Barrower Occupation <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerOccupation" placeholder="Enter Barrower Occupation" id="LoanAmount" required (keyup)="convertToUppercase()">
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerMobile">Barrower Mobile <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerMobile" placeholder="Enter Your Barrower Mobile" onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" minlength="10" id="LoanAmount" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="BarrowerAddress">Barrower Address <span style="color:red">*</span></label>
                                    <input type="text" formControlName="BarrowerAddress" placeholder="Enter Your Barrower Address" id="LoanAmount" required (keyup)="convertToUppercase()">
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="WorkingDays">Working Days <span style="color:red">*</span></label>
                                    <input type="text" formControlName="WorkingDays" onkeypress="return /[0-9]/i.test(event.key)" placeholder="Enter Your Sell With InDays" id="LoanAmount" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="ConNoticeRef">Concillation Ref.Number <span style="color:red">*</span></label>
                                    <input type="text" formControlName="ConNoticeRef" placeholder="Enter ConcillationRef.Number" id="LoanAmount" required>
                                    <br />
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="ReferDate">Dated<span style="color:red">*</span></label>
                                    <input type="text" formControlName="ReferDate" type="date" placeholder="Enter ConcillationRef.Number" id="LoanAmount" required>
                                    <br />
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- If AgreementNo is null, undefined, or empty, show Submit button -->
                                <button *ngIf="NoticeFormID===null" class="btn" (click)="getRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>

                                <!-- If AgreementNo has a value, show Update button -->
                                <button *ngIf="NoticeFormID !==null" class="btn" (click)="updateRegistration(RegisterForm.value)">
                                    <b>Preview</b>
                                </button>
                            </div>

                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</div>
