<!--<p>recoverymanager works!</p>-->


<body>
    <div class="container" id="pdf-content">
        <p><i>As per Section 13(4)(c) in the securitisation and reconstruction of financial assets and enforcement of security interest (SARFAESI) act 2002, Recovery of debts laws(amendment)act 2004, section 8(30 of 2004), for C1.(b)(w.e.f 11.11.2004) & Section 62 of Arbitration and conciliation act 1996</i></p>
        <br /><h2 style="font-size: 16px;"><b>AUTHORIZATION FOR VEHICLE RECOVERY</b></h2>
        <p class="text-center mt-2"><b>TO WHOMSOEVER IT MAY CONCERN,</b></p>

        <p><span style="color:red"> {{RecovererName}}</span>, S/o: <span style="color:red"> {{Recovererfather}}</span>, Age: <span style="color:red"> {{Recovererage}}</span>, <span style="color:red">Occ: {{RecovererOccupation}}</span>, office Address: <span style="color:red"> {{loginDet[0].CompanyName}}, {{loginDet[0].CompanyAddress}}</span>, is hereby appointed as an authorized officer to recover the vehicle from <span style="color:red"> {{BarrowerName}}</span>.</p>
        <p class="mt-1">
            <b>Vehicle Details:</b>
        </p>
        <div class="btn-group">
            <p class="mt-1">- Vehicle Type: <span style="color:red"> {{VehicleType}}</span></p>
        </div>
        <p class="mt-1">- Vehicle Registration Number: <span style="color:red"> {{VehicleRegNo}}</span></p>

        <p class="mt-1">
            <b>Background:</b>
        </p>
        <p><span style="color:red">  {{BarrowerName}}</span>, S/o: <span style="color:red"> {{BarrowerFatherName}}</span>, Age: <span style="color:red"> {{BarrowerAge}}</span>, Occupation: <span style="color:red"> {{BarrowerOccupation}}</span>, Address: <span style="color:red"> {{BarrowerAddress}}</span>, Cell No: <span style="color:red"> {{BarrowerMobile}}</span> was provided an opportunity for conciliation, wide Reference Number <span style="color:red"> {{RefNumber}}</span>, dated <span style="color:red"> {{SelectdDate}}</span> which lapsed without response.</p>
        <p>Pursuant to the conciliation request sent through a designated conciliator, our company has not received any response from <span style="color:red"> {{BarrowerName}}</span>.</p>
        <p>In accordance with applicable laws, due to <span style="color:red"> {{BarrowerName}}</span> non-response and default on obligations, our company has resolved to repossess the vehicle borrowed by <span style="color:red"> {{BarrowerName}}</span>.</p>
        <p>The vehicle will be confiscated and further action will be taken as per law.</p>

        <b>Authorization:</b>
        <p>The company authorizes <span style="color:red"> {{RecovererName}}</span></p>
        <p>1. Impound the vehicle from <span style="color:red"> {{BarrowerName}}</span>.</p>
        <p>2. If <span style="color:red"> {{BarrowerName}}</span> creates any law & order issues, kindly approach the local police.</p>
        <p>3. Seek local police cooperation.</p>
        <p>4. Make representations to the police on behalf of the company.</p>

        <b>Notice to <span style="color:red"> {{BarrowerName}}</span></b>
        <p>You are hereby informed that:</p>
        <p>1. The company will take possession of the vehicle.</p>
        <p>2. You have <span style="color:red"> {{BarrowerWorkingDays}}</span> working days to redeem the vehicle by paying the outstanding loan.</p>
        <p>3. Failure to comply will result in further action.</p>

        <b>Release Conditions:</b>
        <p>The vehicle will be released upon payment of the outstanding loan within {{BarrowerWorkingDays}} working days.</p>

        <p>Please note that this authorization is made in accordance with applicable laws.</p>
        <div class="right">
            <p><span style="color:red"> {{loginDet[0].CompanyName}}</span></p>
            <p><span style="color:red">{{loginDet[0].CompanyAddress}}</span></p>
            <p><span style="color:red">{{loginDet[0].Mobile}}</span></p>
        </div>

        <b>Notes:</b><br />
        1) Furnish this copy to <span style="color:red">{{BarrowerName}}</span> <br />
        2) This Authorisation letter is valid till 45 days from date of issue.<br />
    </div>

    <div class="row">
        <div class="col-lg-8 ">
        </div>
        <div class="col-lg-1 ">
            <button (click)="navigateTOeditform(Noticeid)" class="pdf"><i class="fa fa-edit"></i> Edit  </button>
        </div>
        <div class="col-lg-3">
            <button (click)="downloadPDF()" class="pdf"><i class="fa fa-download"></i> Generate PDF  </button>
        </div>
    </div>
</body>

